.lgin {
    background-color: white;
    border-radius: 50px;
    margin-left: 20px;
    margin-top: 1px;
  }

  @font-face {
    font-family: 'Open sans';
    src: url(../fonts/OpenSans.ttf);
  }
  
  @font-face {
    font-family: 'Open sans light';
    src: url(../fonts/Opensans2.ttf);
  }


  .txt {
    font-style: italic;
  }
  
  body {
    position: relative;
    overflow-x: hidden;
  }
  
  .mag {
    width: 40px;
    height: 25px;
  }
  .msg {
    width: 40px;
    height: 25px;
  }
  .crd {
    width: 40px;
    height: 25px;
  }
  
  .nav-style {
    z-index: 31;
    position: absolute;
    width: 100%;
  }
  
  .click {
   border: 1px solid #ececf1 !important;
    border-radius: 50px;
    margin-left: 20px;
    padding: 4px 0px 4px 2px;
    margin-top: 1px;
  }
  

  #indv {
    background-color: white;
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
    padding: 7px 8px 7px 17px;
    text-decoration: none;
    color: #6c7a8a;
    font-style: italic;
  }
  
  #co-p {
    padding: 7px 17px 7px 8px;
    text-decoration: none;
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
    color: #6c7a8a;
    font-style: italic;
  }

  #individual{
    display: flex
  }
  
 #corporate{
  display:none
 }  

 #individualMob{
  display: block
}

#corporateMob{
display:none
} 
  .btn-group button {
    border: 2px solid #ececf1;
    border-radius: 50px;
    background-color: white;
    padding: 3px 20px 3px 20px;
    margin-left: 20px;
    margin-top: 2px;
  }
  
  .low-nav li {
    list-style: none;
    color: white;
    margin: 0px 20px 0px 15px;
  }
  
  .imp {
    border-radius: ;
    padding: 0px 100px 0px 120px;
  }
  
  .hgh-nav {
    margin-top: -45px;
  }
  .low-nav {
    
    border-bottom: none;
  }
  .test {
    height: 5000px;
  }
  
  .nv-hd {
    box-shadow: 0px 10px 10px -15px #111;
    padding-bottom: 50px;
    transition: all .3s ease-in-out;
    background-color: white !important;
  }

  #myTab{
    transition: all .3s linear;
    width: 70%;
    margin: auto;
    background-color: #ed1b2e;
  }

  #myTabContent{
    top: 14%
  }

  #link1{
    text-decoration: none !important;
    color: white
  }
  
  .btn-chng {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px 10px 0px;
    background-color: none;
    border-top: 0.5px solid #9d9e9e;
  }
  
  .tab-dtl {
    color: black;
    width: 100vw !important;
    background: white;
    position: absolute;
    top: 38% !important;
    padding: 6rem;
    height: 50vh;
    font-family: "Open Sans";
    z-index: -1;
  }
  
  .tab-dtl > li {
    color: black;
  }
  .tb-cnt {
    z-index: -1;
  }
  
  .nv-btn {
    border: none;
    background: none;
    color: white;
    padding: none;
    margin: none;
  }
  
  .nv-btn, .nv-btn1 {
    background: none;
    border: none;
    transition: none;
    font-weight: 700;
    font-size: large;
    padding-right: 0px !important;
    padding-left: 0px !important;

  }

  #life-tab, #policy-tab, #claims-tab, #advise-tab, #about-tab, #readers-tab{
     color: white
  }


  

  .nav-item > button {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  
  .hd-lnk {
    font-weight: 400 !important;
    font-size: 1.3rem !important;
    text-decoration: none;
    color: black;
    font-family: "Open Sans";
  }

  .nav-pad{
    padding-right:16%;
    padding-left: 16%
  }
  .tb-lnk {
    text-decoration: none;
    font-weight: 200;
    color: black;
    font-family: "Open Sans light"
  }
  
  .arr {
    color: red;
    font-size: 30px;
  }
  
  .psn {
    margin-right: 5px;
  }
  .login-btn {
    border: 2px solid #b7b7b8;
    color: #1b365d;
    font-style: italic;
    padding: 10px 100px 10px 100px;
  }
  
  .Online-btn {
    border: 2px solid #b7b7b8;
    color: #1b365d;
    font-style: italic;
    padding: 10px 96px 10px 96px;
    margin-top: 10px;
  }
  
  .Enter-btn {
    border: 2px solid #b7b7b8;
    color: #1b365d;
    font-style: italic;
    padding: 10px 120px 10px 120px;
    margin-top: 10px;
  }
  
  .mb-nv {
    background: none;
    text-decoration: none;
    color: black;
  }
  hr {
    color: grey;
    margin-top: 10px;
  }
  .mb-hd {
    font-size: 20px;
    font-family: "Open Sans";
    font-weight: 400;
  }
  
  .mb-p {
    font-size: 18px;
    font-weight: 300;
  }
  .card-body {
    border: none;
    width: 100% !important;
  }

  #burgerBox{
    display:none
  }
  
  @media (max-width: 991px) {
    .dissapear {
      display: none;
    }

    #burgerBox{
      display: inline
    }
  
    .image {
      width: 120px;
    }

    .nv-hd{
      padding-bottom: 0px;
    }
    .navbar {
       --bs-navbar-padding-x: 0;
      --bs-navbar-padding-y: 0rem;
      position:fixed;
      z-index: 50;
      background-color: white;

  }
  .navbar-toggler-icon {
   
  background-image: url('../images/menus.png') !important; 
 
}

.navbar-toggler:focus {

  box-shadow: none;
}

#burgerBox{
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 5px;
  padding-left:5px;
  background: red;
  border: transparent !important;
  display: 'inline'
}

#navCont{
  padding-right: 0px !important; 
}


}
  
  @media (max-width: 1024px) {
    .low-nav {
    }
  }