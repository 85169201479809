/*PLACE IN APP.CSS FILE
footer {
  overflow-x: hidden;
}*/

.footer-hd {
  font-size: 17px !important;
  color: white !important;
}

.txt > div {
  color: white;
}
.mb-p > p {
  color: white;
}

.footer-img {
  width: 90px;
}

.socio {
  color: white;
  border: 0.1rem solid rgba(255, 255, 255, 0.18);
  border-radius: 50%;
  padding: 10px 5px 10px 5px;
  margin-right: 15px;
  margin-left: 15px;
}

.cl {
  background-color: #1f1f1f;
  color: #b4b4b4;
}

.socio-div {
  background: black;
  align-items: center;
  padding-top: 25px;
  color: #b4b4b4;
}
.p-cl > ul > li {
  color: #b4b4b4;
  padding-top: 20px;
  font-size: 11px;
}
.socio-txt {
  font-size: 11px;
}

.socio-txt > p {
  color: #b4b4b4;
}

.gp {
  background-color: #1f1f1f;
  color: white;
  padding: 25px 15px 25px 25px;
  border-bottom: 1px solid grey;
}
.mb-d {
  color: white;
  font-weight: 200;
  font-size: 18px;
}
.mb-p > p {
  padding-top: 10px;
  font-size: 15px;
  font-weight: 400;
}
.go-plus {
  color: white;
  font-size: 28px;
}
.img {
  width: 120px;
  margin-left: 50px;
  margin-top: 20px;
}

.soc-icn {
  text-align: center;
}

.btm-ft {
  background: black;
}

.btm-ft-p {
  padding-top: 10px;
  font-size: 11px;
  color: #b4b4b4;
}
